import React from "react"
import Helmet from "react-helmet"
import { graphql, StaticQuery } from "gatsby"

// Page (Home, Movies, Blog) || Item page (Movie, actor, serie) || Markdown Blog Post 
//page, post, movie
const Metadata = props => (
  <StaticQuery
    query={graphql`
    query {
      site {
        siteMetadata {
          title
          author
          siteUrl
          description
          twitter_site
        }
      }
    }
  `}
    render={
      data => {
        let siteMetadata = data.site.siteMetadata
        let type = props.type === 'post' && 'article' || props.type === 'movie' && 'video.movie' || 'website'
        let title = props.title ? `${props.title} - ${siteMetadata.title}` : siteMetadata.title
        let description = props.description ? props.description : siteMetadata.description
        let url = props.pathname ? `${siteMetadata.siteUrl}/${props.pathname}` : siteMetadata.siteUrl
        let image = 'https://cinetimes.org/logo.png'
        if (props.image) image = this.props.image
        // image = props.image.childImageSharp === undefined ? `${props.image.src}` : `${siteMetadata.siteUrl}${props.image.childImageSharp.sizes.src}`

        let twitterCard = props.image ? 'summary_large_image' : 'summary'
        let schema
        if (props.type === 'post') {
          schema = [
            {
              "@context": "http://schema.org", 
              "@type": "BlogPosting",
              "headline": title,
              "image": image,
              "genre": props.category, 
              "url": url,
              "datePublished": props.date,
              "dateCreated": props.date,
              "dateModified": props.update,
              "description": props.description,
              // "articleBody": "You can paste your entire post in here, and yes it can get really really long.",
              "author": 
                {
                  "@type": "Person",
                  "name": siteMetadata.author,
                }
            }
          ]
        } else if(props.type === 'movie'){
          schema = [
            {
              "@context": "http://schema.org", 
              "@type": "VideoObject", 
              "url": url, 
              "name": title,
               "author": {
                  "@type": "Person",
                  "name": siteMetadata.author
                },
              "description": description,
              "publisher": title,
              "thumbnailUrl": `${siteMetadata.siteUrl}/${props.poster}`,
              "uploadDate": props.date || null,
              "duration": props.duration || null,
            }
          ]
        } else {
          schema = [
            {
              "@context": "http://schema.org", 
              "@type": "WebSite", 
              "url": siteMetadata.siteUrl, 
              "name": siteMetadata.title,
               "author": {
                  "@type": "Person",
                  "name": siteMetadata.author
                },
              "description": siteMetadata.description,
              "publisher": siteMetadata.title,
            }
          ]
        } 
        schema = JSON.stringify(schema)
        return (
          <Helmet defer={false}>
            <title> {title} </title>
            <meta name="description" content={description} />
        
            {/* Mixed Facebook Opengraph Twitter cards. Twitter fallback on OG*/}
            <meta name="twitter:card"         content={twitterCard} />
            <meta name="twitter:site"         content={siteMetadata.twitter_site}/>
            <meta name="twitter:creator"      content={siteMetadata.twitter_site} />
            <meta name="twitter:image"        content={image}></meta>
            <meta property="og:url"           content={url} />
            <meta property="og:title"         content={title} />
            <meta property="og:type"          content={type} />
            <meta property="og:description"   content={description} />
            <meta property="og:image"         content={image} />

            { typeof props.noindex !== "undefined" && props.noindex === true ?
              <meta name="robots" content="noindex" /> : ''
            }
        
            {/* Schema.org tags */}
            <script type="application/ld+json">
              {schema}
            </script>
          </Helmet>
        )
      }
    }
  />
)

export default Metadata